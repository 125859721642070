import React, { useEffect, useRef } from 'react';
import { useLocation } from '@reach/router';

import {
    grid,
    gridSuccess,
    title as titleClass,
    formWrapper,
    thankYouBox,
    sectionBox,
} from './password-reset.module.scss';

import { ISection } from '../../models/section.model';
import useRtkQueryFormError from '../../hooks/use-rtk-query-form-error';
import { passwordResetForm } from '../../formik/password-reset.form';
import { useUser } from '../../hooks/use-user';

import Section from '../hoc/section';
import FormGenerator from '../molecules/form-generator';
import ThankYouBox from '../molecules/thank-you-box';

export interface IPasswordResetProps {
    className?: string;
    TitleTag?: React.ElementType;
    section: ISection;
}

const PasswordReset: React.FC<IPasswordResetProps> = ({ className = '', TitleTag, section }) => {
    const { sectionId, css, style } = section;
    const [title, successTitle, successDescription] = section.content.texts;
    const [successButton] = section.content.buttons;
    const userState = useUser();
    const { formikRef, globalErrorMessage } = useRtkQueryFormError(userState.resetPassword.errors);
    const sectionRef = useRef<HTMLElement>(null);
    const { search } = useLocation();
    const urlParams = new URLSearchParams(search);
    const token = urlParams.get('token');

    useEffect(() => {
        formikRef.current?.setFieldValue('token', token || '');
    }, [formikRef, token]);

    useEffect(() => {
        if (!sectionRef.current) return;
        if (userState.resetPassword.isSuccess) {
            sectionRef.current.scrollIntoView();
        }
    }, [userState.resetPassword.isSuccess]);

    return (
        <Section
            sectionId={sectionId}
            className={`${className} ${sectionBox}`}
            classes={{
                container: `${grid} ${userState.resetPassword.isSuccess ? gridSuccess : ''}`,
                title: titleClass,
            }}
            TitleTag={TitleTag}
            css={css}
            style={style}
            title={title}
            ref={sectionRef}
        >
            {!userState.resetPassword.isSuccess ? (
                <FormGenerator
                    formRef={formikRef}
                    className={formWrapper}
                    formikProps={{
                        onSubmit: (values) => userState.resetPassword.fetch(values),
                    }}
                    name="PasswordResetForm"
                    fields={passwordResetForm}
                    errorMessage={globalErrorMessage}
                    submitLoading={userState.resetPassword.isLoading}
                />
            ) : (
                <ThankYouBox
                    className={thankYouBox}
                    data={{
                        title: successTitle || '',
                        description: successDescription || '',
                        button: successButton,
                    }}
                />
            )}
        </Section>
    );
};

export default PasswordReset;
